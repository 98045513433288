.root {
  width: 100%;
  height: 100%;
  padding-top: 12px;
}

.loaderContainer {
  padding: 48px 0;
}

.header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart {
  height: calc(100% - 96px);
}
