@import 'src/themes/constants';

.chart {
  margin-bottom: $generic-margin;

  .toplabels {
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;
      > span {
        max-width: 131px;
        margin-left: 10px;
      }
    }
  }

  .kernel {
    margin-top: $generic-margin;
    display: flex;
    justify-content: space-between;

    .pierange {
      margin-top: -30px;
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      margin-left: 100px;

      > div {
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        justify-content: space-between;
        padding-left: 15px;
      }
    }
  }
}

.table {
  min-height: 500px;
}
