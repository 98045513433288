.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  transition: background-color 0.3s cubic-bezier(0, 2.13, 1, -1.11);
  border-radius: 4px;
  padding: 2px;
}

.inputs {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
}

.input + .input {
  margin-left: 8px;
}

.input {
  min-width: 96px;
}

.warn {
  background-color: rgba(250, 128, 114, 0.517);
}

.success {
  background-color: rgba(58, 197, 83, 0.499);
}
