.layout {
  display: grid;
  grid-template-columns: 60fr 40fr;
  grid-template-rows: 50px auto;
  min-width: 600px;
  min-height: 280px;

  .beverages {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .caption {
      margin-left: 6px;
    }
  }
  .top {
    grid-row-end: span 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;

    .rate {
      display: flex;
      width: 100%;
      border-bottom: solid 1px #e8e8e8;

      > div {
        border-radius: 10px;
        min-width: 19px;
        height: 19px;
        line-height: 19px;
        font-size: 10px;
        text-align: center;
        color: white;
        margin-right: 6px;
        margin-bottom: 16px;
        padding: 0px 6px;
      }
    }

    .note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }
      .beverages {
        text-align: right;
        overflow: visible;
        white-space: nowrap;
      }
    }
  }
}
