.content {
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
  border-radius: 0;
}

.emails {
  margin-top: 12px;
  width: 320px;
  max-width: 100%;
}
