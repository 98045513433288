@import 'src/themes/constants';

.link {
  :global .anticon {
    fill: $link-color;
  }
  color: $link-color;
}

.link-on {
  :global .anticon {
    fill: #4cd964;
  }
  color: #4cd964;
}

.link-off {
  :global .anticon {
    fill: #ff3b30;
  }
  color: #ff3b30;
}
