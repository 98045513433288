.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
}

.icon {
  margin-right: 12px;
  :global .anticon {
    fill: black;
  }
}

.title {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.caption {
  font-size: 12px;
}
