@import 'src/themes/constants';

.root > div {
  display: flex;

  .chart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .filters {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > * {
        margin-right: 24px;
      }
    }

    .curves {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  .sider {
    width: 185px;

    .sidertitle {
      .curency {
        margin-right: 8px;
        color: $link-color;
      }
      margin-bottom: 34px;
    }

    .rangereport {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .values {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 8px;
    }

    :global .ant-divider-horizontal {
      margin: 0px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .root > div {
    flex-direction: column;
    align-items: center;

    .chart {
      .filters {
        & > button {
          display: none;
        }
      }

      .curves {
        display: none;
      }
    }
    .sider {
      padding-top: 20px;
      align-self: flex-start;
    }
  }
}
