.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 16px;

  &::after {
    content: ' ';
    background: linear-gradient(rgba(80, 80, 80, 0), rgba(255, 255, 255, 1));
    width: 100%;
    min-height: 50px;
    position: absolute;
    bottom: 50px;
    left: 0px;
  }

  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e6ea;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 30px;
    padding-right: 5px;

    &:nth-child(5) {
      border-bottom: none;
    }

    .dot {
      flex-shrink: 0;
      border-radius: 4px;
      width: 8px;
      height: 8px;
    }
    .label {
      color: black;
      min-width: 100px;
      line-height: 20px;
      margin-left: 8px;
      flex-grow: 1;
    }
    .value {
      text-align: right;
      line-height: 20px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: visible;
    }
  }
}
