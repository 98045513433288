@import 'src/themes/constants';

.wrap {
  .items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    > * {
      line-height: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 12px;
      overflow: hidden;
      text-align: center;
      margin: 1.5px;
      padding: 0px 5px;
      color: white;
      font-size: 12px;
    }
  }

  :global .ant-picker-calendar-date {
    max-height: 64px;
  }

  .loader {
    position: absolute;
    width: calc(100% - 48px);
    height: calc(100% - 98px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;
  }

  .head {
    z-index: 4;
    display: flex;
    align-items: center;

    .month {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -120px;

      .monthtext {
        cursor: pointer;
        min-width: 225px;
        text-align: center;
        text-transform: capitalize;
        color: $link-color;
        font-weight: 600;
      }
    }
  }
}
