.description {
    max-width: 800px;
}

.settingsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
}

.settingsLeft {
    max-width: 400px;
    border-right: 1px solid #f0f0f0;
    margin-right: 20px;
    padding: 10px 20px;

    @media screen and (max-width: 840px) {
        border-right: none;
    }
}

.settingsRight {
    max-width: 420px;
    padding: 10px 20px;
}

.alldayCheckbox {
    margin-left: 12px
}

.datePicker {
    width: 300px;
}

.timePicker {
    width: 280px
}
