.root {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  :global .ant-table-wrapper {
    width: 100%;
  }

  :global .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  :global .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fafafa;
  }
}
