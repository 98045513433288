.root > div {
  display: flex;
  height: 250px;

  .chart {
    align-self: center;
    width: 300px;
  }

  .list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    .item {
      margin-left: 50px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
      .label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .phonetext {
          display: none;
        }
        .mark {
          width: 15px;
          height: 15px;
          border-radius: 7.5px;
          margin: 0px 7.5px 0px -7.5px;
        }
      }
      .value {
        background: #eefbfb;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 4px 14px 4px 14px;

        > * + * {
          margin-left: 8px;
        }
      }
    }
  }

  .emergency {
    text-align: center;
    margin-top: -130px;
  }

  .loadin,
  .emergency {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .root > div {
    flex-direction: column;
    height: 600px;
    .chart {
      width: inherit;
    }
    .list {
      .item {
        margin-left: 0px;
        .label {
          .text {
            display: none;
          }
          .phonetext {
            display: block;
          }
        }
      }
    }
  }
}
