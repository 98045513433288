.form {
  display: inline-block;
  margin: -1.5px 0;

  input {
    max-width: 150px;
    margin-right: -48px;
    padding-right: 48px;
  }
}
