@import 'src/themes/constants';

.wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px 10px 10px;
  }

  .button {
    border: none;
  }
}
