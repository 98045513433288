.whole {
  overflow-y: auto;
  .title {
    h4 {
      font-size: 12px;
      font-weight: 400;
    }
    border-bottom: 1px solid #d9d9d9;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 61px;
      width: 150px;
      padding: 8px 15px;
      background-color: #fafafa;
      border-radius: 8px;

      .caption {
        color: #8c8c8c;
      }
    }
  }

  .top {
    padding-bottom: 10px;
    flex-grow: 1;

    > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      > div {
        display: flex;
        flex-direction: column;

        .name {
          padding: 5px 0px;

          ::first-letter {
            color: #bfbfbf;
          }
        }
      }
    }
  }
}
