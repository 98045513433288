@import 'src/themes/constants';

$icon-size: 34px;

.menu {
  margin-top: $generic-margin;
  border-top-right-radius: $border-radius-base;
  padding-top: 37px;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;

  > li {
    * {
      direction: ltr;
    }
    direction: ltr;
  }

  // Disable jumping effect while menu toggling
  :global(.ant-menu-item),
  :global(.ant-menu-item .anticon),
  :global(.ant-menu-item .anticon svg) {
    transition: none;
  }

  // Disable menu icon double margin
  :global(.ant-menu-item .anticon svg) {
    margin-right: 0;
  }

  @include mobile {
    top: 56px;
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 12px 0 32px 0;
    margin: 0;
  }

  &:global(.ant-menu.ant-menu-inline-collapsed) {
    width: $small-menu-width;
    padding-top: 0;

    :global(.ant-menu-item),
    :global(.ant-menu-submenu .ant-menu-submenu-title) {
      display: flex;
      align-items: center;
      height: $small-menu-width - 8px;
      padding: 0 32px !important;
    }

    :global(.anticon),
    :global(.ant-menu-submenu > .ant-menu-submenu-title .anticon),
    :global(.ant-menu-item .anticon),
    :global(.anticon > svg) {
      font-size: $icon-size;
    }

    :global(.ant-menu-selected::after),
    :global(.ant-menu-item-selected::after) {
      transform: scaleY(1);
      opacity: 1;
      transition:
        transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    @include mobile {
      display: none;
    }
  }
}
