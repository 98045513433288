.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .switch {
    background-color: #2979bd;
    min-width: 130px;
  }

  .chart {
    display: grid;
    grid-template-columns: 280px auto;
    width: 100%;

    .value {
      padding: 5px 0px;
      > div {
        &:hover {
          background-color: #4889da;
        }
        height: 24px;
        background-color: #4f91e4;
      }
    }
    .name {
      align-self: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: right;
      margin-right: 5px;
    }
    .labels {
      display: flex;
      justify-content: space-between;
    }
  }
}
