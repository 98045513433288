@import 'src/themes/constants';

.root {
  display: grid;
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  grid-template-columns: 676fr 440fr;
  grid-template-rows: 600px auto;

  min-height: 135vh;
  overflow: hidden;

  .top {
    display: flex;
    flex-direction: column;
  }

  .table {
    grid-column-end: span 2;
    overflow-x: hidden;
    min-height: 0px;
    & > div {
      height: 100%;
    }
    margin-bottom: $generic-margin;
  }
}

.details {
  padding-left: 16px;
  background-color: #fbfbfb;
  padding-bottom: 10px;
  :global(.ant-table-wrapper) {
    padding-bottom: 10px;
  }
  .detailschild > td {
    :global .ant-table-wrapper:only-child .ant-table {
      margin-left: 0px;
    }
  }
}
