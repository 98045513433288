.badge {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 118.95px;
  height: 88.04px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px;

  .head {
    margin-bottom: 6px;

    display: flex;
    align-items: flex-start;

    .subvalue {
      color: #8c8c8c;
    }
    .rise {
      fill: #54da33;
    }
    .fail {
      fill: #f5222d;
    }
    .value {
      display: flex;
      align-items: baseline;
    }
  }
}

.actable {
  background-color: #eefbfb;
  transition: all 0.6s;
  cursor: pointer;
}

.actable:hover {
  background-color: #e0f8f8;
}
