.root {
  width: 100%;
  height: calc(100% - 32px);
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tooltip {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}
