.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 295px;
  margin: 0px 20px;
  padding: 5px 0;
  border-bottom: 1px solid #d3d3d3;
  transition: background-color 0.1s linear;

  .label {
    width: 60%;
    flex-shrink: 0;
  }

  .text {
    text-align: right;
  }

  &:hover {
    background: #fafafa;
  }
  &:last-child {
    border-bottom: none;
  }
}

.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
