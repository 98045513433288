@import 'src/themes/constants';

.root {
  display: grid;
  grid-template-columns: 701fr 464fr;

  > :nth-child(1),
  > :nth-child(4) {
    grid-row-start: span 2;
  }
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  padding-bottom: $generic-margin;
}

.title {
  display: flex;

  :global .anticon {
    fill: $link-color;
    margin-right: 8px;

    > svg {
      fill: $link-color;
    }
  }
}

.badges {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  flex-grow: 1;
}

.card {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.clearance {
  .title {
    display: flex;
    align-items: center;
    > h4 {
      flex-grow: 1;
    }
    .calendar {
      flex-grow: 2;
      > * {
        display: flex;
        align-items: center;
        color: $link-color;

        :global .anticon {
          border-radius: 8px;
          background: #eefbfb;
          display: block;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0px;

          > svg {
            margin-top: -2px;
          }
        }
      }
    }
  }
}
