.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -31px;

  > :first-child {
    margin-bottom: 8px;
  }
}

.calendar {
  :global .ant-modal-content {
    min-width: 800px;
  }
}

.link-on {
  :global .anticon {
    fill: #4cd964;
  }
  color: #4cd964;
}

.link-off {
  :global .anticon {
    fill: #ff3b30;
  }
  color: #ff3b30;
}
