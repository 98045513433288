.root > div {
  .chart {
    height: 750px;
  }
}

@media screen and (max-width: 1000px) {
  .root > div {
    .chart {
      margin: 0px -25px;
    }
  }
}
