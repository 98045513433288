.cardHeader {
  .headerActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .search {
    width: 256px;
    margin-right: 16px;
  }
}

.modalDescription {
  margin-bottom: 16px;
}

.table {
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
  border-radius: 0;
}
