.viewer {
  :global {
    .ant-table-cell {
      height: 65px;
    }

    .ant-form-item-explain {
      margin-bottom: -24px;
    }

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}

.start-edditing-button {
  display: flex;
  justify-content: flex-start;
}

.modal-bottom {
  margin-bottom: 8px;
}

.bottom {
  margin-top: 16px;
  margin-bottom: 24px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.nomodal {
  margin-bottom: 8px;
  max-height: 100%;
  overflow-y: auto;
}

.norecipe {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.location {
  justify-content: space-between;
  display: flex;
  align-items: center;

  :global .ant-input {
    width: 200px;
  }

  span {
    white-space: nowrap;
  }
}

.ingredients {
  > input {
    display: none;
  }

  td {
    padding-bottom: 8px;
  }
}

.required::after {
  content: ' *';
  color: red;
}
