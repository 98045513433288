@import 'src/themes/constants';

$initial-sticky-offset: -2px;
$sticky-row-height: 45px;

.whole {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  > div.buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    :global .ant-input-affix-wrapper {
      max-width: 256px;
    }
  }

  .row {
    display: flex;
    transition: background 0.3s;
    border-bottom: 1px solid #e8e8e8;

    &:hover {
      .hidehover > * {
        opacity: 1;
      }
      background-color: #fafafa;
    }

    .hidehover > * {
      opacity: 0;
    }

    &.selected {
      background: #edf8fc;
      border-color: rgba(0, 0, 0, 0.03);

      &:hover {
        background: #e4f5fb;
      }
    }

    .virtual-table-cell {
      box-sizing: border-box;
      padding: 16px;
      transition: background-color 1s linear;
    }

    .lastcolumn {
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
      align-items: center;

      > :first-child {
        margin-left: -5px;
        margin-right: 5px;
      }
    }
  }

  .highlightnew {
    background-color: #fffffa;
  }

  .loader {
    width: 100%;
    height: 300px;
  }

  .collapseicon {
    transition: transform 0.24s;
  }

  table {
    table-layout: fixed;
    th.sortable {
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
      & > div {
        user-select: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  &.sticky-thead {
    :global {
      // We need to sticky <th> not <thead> (https://css-tricks.com/position-sticky-and-table-headers/)
      .ant-table-thead > tr > th {
        position: sticky;
        z-index: 1;
      }

      .ant-table-thead > tr > th {
        top: $initial-sticky-offset;
      }

      .ant-table-content .ant-table-content .ant-table-thead > tr > th {
        top: $initial-sticky-offset + $sticky-row-height;
      }

      .ant-table-content
        .ant-table-content
        .ant-table-content
        .ant-table-thead
        > tr
        > th {
        top: $initial-sticky-offset + $sticky-row-height * 2;
      }
    }
  }

  &.sticky-row {
    .row {
      position: sticky;
      top: $initial-sticky-offset;
      z-index: 2;
      background: white;
    }
  }

  &.sticky-row.sticky-thead {
    :global {
      .ant-table-thead > tr > th:hover {
        background: #eee;
      }
      .ant-table-thead > tr > th {
        top: $initial-sticky-offset + $sticky-row-height;
      }

      .ant-table-content .ant-table-content .ant-table-thead > tr > th {
        top: $initial-sticky-offset + $sticky-row-height * 2;
      }

      .ant-table-content
        .ant-table-content
        .ant-table-content
        .ant-table-thead
        > tr
        > th {
        top: $initial-sticky-offset + $sticky-row-height * 3;
      }
    }
  }
}
