@import 'src/themes/constants';

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    margin-top: $generic-margin;
  }

  h2 {
    margin-top: 0px;
  }
}

.recover {
  .head {
    display: flex;
    align-items: center;

    h2 {
      margin: 0px;
      text-align: center;
      flex-grow: 1000;
    }
    padding-bottom: $generic-margin;

    .goback {
      font-size: 21px;
      color: #595959;
    }
  }

  line-height: 22px;

  a {
    font-weight: 600;
  }
}
