@import 'src/themes/constants';

.charthead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .values {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      margin-left: 30px;

      > span:first-child {
        margin-right: 11px;
      }
    }
  }
}

.table {
  max-height: 70vh;
  min-height: 70vh;
  > div {
    height: 100%;
  }
}

.chart {
  margin-bottom: $generic-margin;
  height: 440px;
}
