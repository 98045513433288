.nodata {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  height: 100%;

  > svg {
    margin-bottom: 30px;
    transform: scale(1.3);
  }

  .nodatatext {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.margin {
  padding-top: 150px;
}
