@import 'src/themes/constants';

.root {
  display: grid;
  grid-template-columns: 701fr 464fr;

  > :nth-child(1),
  > :nth-child(4) {
    grid-row-start: span 2;
  }
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  padding-bottom: $generic-margin;
}
