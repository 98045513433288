.whole {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > div:last-child {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    > span {
      white-space: nowrap;
    }
  }
}
