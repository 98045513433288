.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  .diagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: transform 0.15s ease-in-out;

    .label {
      margin-top: 8px;
      width: 120px;
      text-align: center;
    }

    :global(.ant-progress-text) {
      font-weight: 500;
      font-size: 16px;
      color: inherit;
    }

    &:hover {
      transform: scale(1.03);
      cursor: pointer;
    }
  }
}
