@import 'src/themes/constants';

.wrap :global(.ant-modal-body) {
  .table {
    max-height: calc(80vh - 110px);
    min-height: 500px;
    overflow-y: auto;
    margin-bottom: $generic-margin;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}

.footer {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.popupconfirm {
  min-width: 510px;
}
