@import 'src/themes/constants';

.login {
  background-image: url('./loginbg.jpeg');
  background-size: cover;
  background-position: 100% 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;

  > p.contacts {
    flex-grow: 1000;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;

    > a {
      color: white;
    }
  }

  .hide {
    opacity: 0;
  }

  .content {
    padding: $generic-margin;
    width: 330px;
    background-color: white;
    border-radius: 8px;
  }

  .version {
    color: #bfbfbf;
  }

  .logo {
    margin-top: calc(25vh / 2 - 75px);
    margin-bottom: calc(25vh / 2 - 75px);
    height: 150px;
    width: 200px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./logo-white.svg');
  }
}
