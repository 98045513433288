@import 'src/themes/constants';

.root > div {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;

  .groups {
    display: flex;
    justify-content: space-between;
    line-height: 50px;

    a {
      text-decoration: underline;
    }
  }

  .conditions {
    margin-bottom: 10px;

    .state {
      height: 40px;
      box-sizing: border-box;
      padding-left: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 8px;
      background: #eefbfb;

      margin: 3px 0px;

      .value {
        width: 20px;
      }
    }
  }

  .icon {
    margin-right: 8px;
    fill: $link-color;
  }

  .state,
  .equipment,
  .outdatedtasks,
  .groups {
    flex-grow: 1;
  }
  .downtime.sublabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global .ant-divider-horizontal {
    margin: 0px 0px 10px 0px;
  }
}

.danger > div,
.danger > span {
  color: #f5222d;
}
