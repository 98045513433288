.root > div {
  display: flex;
  flex-direction: column;
  height: 100%;

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    flex-grow: 1;
  }

  .selectors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > * {
      margin-right: 24px;
    }
  }
}
