@import 'src/themes/constants';

.root {
  display: grid;
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  grid-template-columns: auto 228px;

  min-height: 135vh;
  overflow: hidden;

  .table {
    grid-column-end: span 2;
    overflow-x: hidden;
    min-height: 0px;
    & > div {
      height: 100%;
    }
    margin-bottom: $generic-margin;
  }
}

.chartcard > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;

  .chartchead {
    display: flex;
    > div:last-child {
      margin-left: 50px;
    }
  }

  > .chart {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .chartloader {
      position: absolute;
      width: 100%;
      height: calc(100% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 100;
    }
  }
}

.loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  padding-left: 16px;
  background-color: #fbfbfb;
}
