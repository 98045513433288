@import 'src/themes/constants';

.grid {
  display: grid;
  grid-template-columns: auto 228px;
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  max-height: calc(100vh - 180px);
  min-height: 1000px;

  .table {
    overflow: hidden;
    grid-column: span 2;
  }

  .legend {
    display: flex;
    align-items: flex-start;
    > div {
      margin-right: $generic-margin;
    }
  }

  .stats > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    &:before,
    &:after {
      display: none;
    }

    > div > div {
      display: flex;
      align-items: baseline;
    }

    .title {
      svg {
        fill: $link-color;
        margin-right: 8px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
