.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  cursor: pointer;

  .title {
    margin-right: 12px;
  }
}

.content {
  background-color: #fafafa;
  display: grid;
  grid-template-columns: 2fr 2fr;
}
