.main {
  grid-template-columns: 648fr 265fr 252fr;
  display: grid;
  margin: -12px;

  > :nth-child(1) {
    grid-column-start: span 2;
  }

  > :nth-child(2) {
    min-width: 228px;
  }

  > :nth-child(3) {
    grid-column-start: span 3;
  }

  > :nth-child(5) {
    grid-column-start: span 2;
  }

  > * {
    margin: 12px;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    display: flex;
    flex-direction: column;
  }
}
