@import 'src/themes/constants';

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .title {
    min-height: $header-height;
    flex-grow: 1;
    max-width: 200px;
    background-image: url('./logo-dark-blue.svg');
    background-size: 100% ($header-height - 10);
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include mobile {
      height: 45px;
      width: 100px;
      flex-grow: 0;
    }
  }

  .menuButton {
    font-size: $generic-icon-size;
    cursor: pointer;
    user-select: none;
    fill: $link-color;
    margin-left: $generic-margin;
    margin-right: -($generic-margin + $generic-icon-size);

    @include mobile {
      margin: 0;
      padding: $mobile-padding;
      box-sizing: content-box;
    }
  }

  .settings {
    margin-right: $generic-margin;
    margin-left: -114.5px - $generic-margin;
    display: flex;
    align-items: center;
    cursor: pointer;

    .gear {
      font-size: $generic-icon-size;
      margin-right: 8px;
      margin-left: 8px;
    }

    @include mobile {
      margin: 0;

      .gear {
        margin: 0;
        padding: $mobile-padding;
        box-sizing: content-box;
      }

      .label {
        display: none;
      }
    }
  }
}
