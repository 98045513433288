.sum {
  display: flex;
  margin-top: 30px;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.top {
  flex-grow: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  .title {
    h4 {
      font-size: 12px;
      font-weight: 400;
    }
    border-bottom: 1px solid #d9d9d9;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;

    > div {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      position: relative;
      padding-left: 15px;
    }
  }
}
