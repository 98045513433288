@import 'src/themes/constants';

.text {
  display: block;
  line-height: 1.2;
  font-size: $value-size-m;
  color: $value-color;
}

.s {
  font-size: $value-size-s;
}

.l {
  font-size: $value-size-l;
}

.xl {
  font-size: $value-size-xl;
}

.xxl {
  font-size: $value-size-xxl;
}

.xxxl {
  font-size: $value-size-xxxl;
}
