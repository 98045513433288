.head {
  .actions {
    margin-bottom: 8px;
  }

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    :global .ant-btn {
      border: none;
    }
  }
}

.submenu {
  :global .ant-menu-light {
    background-color: transparent;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1000px) {
  .head {
    .titleRow {
      flex-wrap: wrap;
    }
  }
}
