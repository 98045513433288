.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.loader {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
