@import 'src/themes/constants';

.container {
  width: 100%;
  border-radius: 0 0 12px 12px;
  padding: 18px 24px;
  color: white;
  background-color: #51b8ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-warning {
  background-color: #ffcc00;
}

.container-danger {
  background-color: #ff3b30;
}

.container-success {
  background-color: #4cd964;
}

.close {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 24px;

  & svg {
    fill: white;
  }
}
