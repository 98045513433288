.header {
  width: 100%;
  display: grid;
  position: sticky;
  top: -30px;
  background-color: white;
  z-index: 1;
  cursor: pointer;
}

.content {
  background-color: #fafafa;

  .row {
    width: 100%;
    display: grid;
  }
}
