@import 'src/themes/constants';

.root {
  display: grid;
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  grid-template-columns: auto 228px;
  height: 100%;

  min-height: 1000px;
  overflow: hidden;
  .detergent {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .table {
    grid-column-end: span 2;
    overflow-x: hidden;
    min-height: 0px;
    & > div {
      height: 100%;
    }
    margin-bottom: $generic-margin;
  }
}
