.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & :global(.ant-upload.ant-upload-select-picture-card) {
    margin-right: 0;
    width: 200px;
    height: 200px;
  }
}
