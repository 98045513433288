@import 'src/themes/constants';

.condition {
  .title {
    margin-bottom: 10px;
  }
}
.beverages {
  > div {
    display: flex;
    flex-direction: column;
    max-height: 290px;

    .title {
      margin-bottom: 20px;
    }

    :global {
      .ant-table-thead {
        display: none;
      }
    }
    .table {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
.onlybeverages {
  grid-row-start: span 2;
  > div {
    max-height: 480px;
  }
}

.statistic {
  height: 152px;
}
