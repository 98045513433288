.group {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
  }

  + .group {
    margin-top: 16px;
  }
}
