@import 'src/themes/constants';
.active,
.basic {
  margin-left: -7px;
  margin-top: -7px;
}
.active,
.basic:hover {
  fill: $link-color;
}

.progress {
  margin-left: -70px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > :first-child {
    flex: 1;
  }
}
