.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e6ea;
    width: 100%;
    flex-grow: 1;

    &:nth-child(5) {
      border-bottom: none;
    }

    .dot {
      flex-shrink: 0;
      border-radius: 4px;
      width: 8px;
      height: 8px;
    }
    .label {
      color: black;
      min-width: 100px;
      line-height: 20px;
      margin-left: 8px;
      flex-grow: 1;
    }
    .value {
      text-align: right;
      line-height: 20px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: visible;
    }
  }
}
