.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .actions {
    margin-top: 32px;
  }
}

.avatar {
  margin-right: 20px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
