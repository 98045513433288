$border-radius-base: 8px;
$generic-margin: 24px;
$generic-icon-size: 24px;
$header-height: 64px;
$menu-width: 255px;
$transition-duration: 0.3s;
$small-menu-width: 100px;

$link-color: #2979bd;
$text-color: #595959;
$value-color: #262626;

$caption-size: 11px;
$value-size-s: 12px;
$value-size-m: 14px;
$value-size-l: 16px;
$value-size-xl: 20px;
$value-size-xxl: 24px;
$value-size-xxxl: 42px;

$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 480px;

$mobile-padding: 10px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}
