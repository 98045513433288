@import 'src/themes/constants';

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.stats > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  &:before,
  &:after {
    display: none;
  }

  > div > div {
    display: flex;
    align-items: baseline;
  }

  .title {
    svg {
      fill: $link-color;
      margin-right: 8px;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
