@import 'src/themes/constants';

.root {
  display: grid;
  column-gap: $generic-margin;
  row-gap: $generic-margin;
  grid-template-columns: auto 258px;
  height: 100%;

  min-height: 1000px;
  overflow: hidden;

  .table {
    grid-column-end: span 2;
    overflow-x: hidden;
    min-height: 0px;
    & > div {
      height: 100%;
    }
    margin-bottom: $generic-margin;
  }
}

.chart > div > div {
  height: 380px;
  width: calc(100% - #{2 * $generic-margin});
  display: flex;
  align-items: center;
  justify-content: center;
}
