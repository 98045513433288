@import 'src/themes/constants';

.main {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: $menu-width auto;
  grid-template-rows: $header-height max-content auto;

  .header {
    grid-column-start: 1;
    grid-column-end: 3;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }

  .notification {
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0;
  }

  .content {
    transition: all $transition-duration;
    overflow-x: auto;
    overflow-y: auto;
    padding: $generic-margin $generic-margin 0 $generic-margin;
    display: flex;
    flex-direction: column;
  }

  @include mobile {
    width: 100%;
    display: block;

    .header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }

    content {
      padding: $header-height + $mobile-padding $mobile-padding;
      overflow: initial;
    }
  }
}

.smallMenu.main {
  grid-template-columns: $small-menu-width auto;
}
