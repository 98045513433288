.container {
  &:global(
      .ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header
    ) {
    padding: 16px 80px 16px 16px;
  }

  &:global(.ant-collapse-borderless > .ant-collapse-item) {
    border-bottom: 1px solid #e8e8e8;
  }

  & :global(.ant-collapse-content > .ant-collapse-content-box) {
    padding: 0;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;

  &:hover {
    background-color: rgba(200, 200, 200, 0.05);
  }
}
