@import 'src/themes/constants';

.color {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0px 5px -7px 0px;
}

.clockContainer {
  display: flex;
  align-items: center;
}

.clock {
  > div {
    border-radius: 14px;
    width: 167px;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    background: #f3f5f7;
    white-space: nowrap;

    .clockicon {
      margin: 1px 6px -1px 6px;
      fill: $link-color;
    }
  }
}
