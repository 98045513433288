@import 'src/themes/constants';

.space {
  display: flex;
  align-items: center;
  > * {
    margin: 0px 2px;
  }
}

.filters-block {
  border-radius: $border-radius-base;
  background: #fbfbfb;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px;
  max-width: 60vw;

  .filters {
    display: flex;
    flex-wrap: wrap;
    > * {
      margin: 10px;
    }
  }
}
