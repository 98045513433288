.growth {
  .rise,
  .fail {
    transform: scaleX(0.7);
  }
  .rise {
    fill: #54da33;
  }
  .fail {
    fill: #f5222d;
  }
}
