.wrap {
  padding: 10px;
  .item {
    height: 100%;
  }
  .item > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      justify-content: space-between;
      .label {
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        .text {
          margin-top: 5px;
          .subtitle {
            margin-right: -100px;
          }
        }
        .anchor {
          margin-left: -22px;
          cursor: grab;
          opacity: 0.4;

          &:active {
            cursor: grabbing;
          }
        }
      }
      .toolbar {
        opacity: 0;
        transition: opacity 0.2s linear;
        display: flex;
      }
    }
    &:hover {
      .header {
        .label {
          .anchor {
            opacity: 1;
          }
        }
        .toolbar {
          opacity: 1;
        }
      }
    }

    .datepicker {
      display: flex;
      justify-content: flex-start;
      > div {
        background-color: #f3f5f7;
        border-radius: 15px;
        line-height: 30px;
        color: #9a9a9a;
        &:hover {
          background-color: #eeeeee;
        }
        cursor: pointer;
        user-select: none;
        padding: 0px 15px;
      }
    }
  }
}
