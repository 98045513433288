.card {
  flex: 1;
  margin-bottom: 24px;
  min-height: 0;

  :global .ant-card-body {
    height: 100%;
  }
}

.noMargin {
  margin-bottom: 0;
}
