@import 'src/themes/constants';

.root {
  .card {
    margin-bottom: $generic-margin;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .title {
      display: flex;
      align-items: center;
      height: 22px;
      margin-bottom: 20px;
      .titletext {
        margin-right: 15px;
      }
    }
  }

  .filter {
    max-width: 256px;
    margin-bottom: 12px;
  }

  .sync {
    color: rgb(100, 140, 215);
  }
  .sync.synced {
    color: green;
  }

  .sync.notSynced {
    color: red;
  }
}
